export default {
  breakpoints: ['760px', '1024px', '1280px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // col = 92, margin = 16
  // colN = (N*92) + ((N-1)*16)
  sizes: {
    col1: 92,
    col2: 200,
    col3: 308,
    col4: 416,
    col5: 524,
    col6: 632,
    col10: 1064,
    col12: 1280,
  },
  colors: {
    text: "#333333",
    background: "#ffffff",
    primary: 'magenta',
    hotRed: '#F2360D',
    contiYellow: '#FCB134',
    almond: '#EEEDDC',
    secondary: "#ff6347",
  },
  fonts: {
    body: '"Noe Text", "Helvetica Neue", sans-serif',
    heading: '"Noe Display"',
    sans: "proxima-nova", 
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    body: 1.3,
    single: 1,
    heading: .9,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'bold',
    },
  },
  fontSizes: [14, 17, 20, 24, 36, 48, 60],
  styles: {
    h1: {
      fontSize: [4,5],
      lineHeight: 1,
      fontFamily: 'heading',
      fontWeight: 'bold', 
    },
    h2 : {
      fontSize: [2,3],
      lineHeight: .9,
      fontFamily: 'heading',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 3,
      lineHeight: 1,
      fontFamily: 'heading',
      fontWeight: 'bold',
    },
    p: {
      fontFamily: 'body',
      fontSize: 1,
      lineHeight: 1.3,
    },
    a: {
      textDecoration: 'none',
      ':hover': {
        color: 'text',
        bg: 'contiYellow',
      }
    },
  },
  buttons: {
    primary: {
      // color: 'background',
      fontSize: 0,
      fontFamily: 'sans',
      textTransform: 'uppercase',
      bg: 'text',
      '&:hover': {
        bg: 'hotRed',
        color: 'white',
      }
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
  },
}